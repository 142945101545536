@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  scrollbar-width: thin;
}

.login-page {
  background-image: url("images/bg-login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: padding-box;
  min-height: 100vh;
}


input[type=checkbox] {
  cursor: pointer;
}

input[type=checkbox]:checked {
  background-color: rgba(31, 41, 55, 1);
}

input[type=checkbox]:focus {
  box-shadow: 0;
}


/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(248, 250, 252);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dc2626;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b91c1c;
  cursor: pointer;
}